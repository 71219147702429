import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const AirtableContactForm = () => {
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const iframe = iframeRef.current;
    const handleLoad = () => {
      setIsLoading(false);
      console.log('Iframe loaded, injecting script');
      // Inject script into iframe
      iframe.contentWindow.postMessage({
        type: 'INJECT_SCRIPT',
        script: `
          console.log('Script injected into Airtable iframe');
          function addListeners() {
            const submitButton = document.querySelector('button[type="submit"]');
            const form = document.querySelector('form');
            
            if (submitButton) {
              console.log('Submit button found');
              submitButton.addEventListener('click', (e) => {
                console.log('Submit button clicked');
                window.parent.postMessage({type: 'AIRTABLE_BUTTON_CLICKED'}, '*');
              });
            } else {
              console.log('Submit button not found');
            }
            
            if (form) {
              console.log('Form found');
              form.addEventListener('submit', (e) => {
                console.log('Form submitted');
                window.parent.postMessage({type: 'AIRTABLE_FORM_SUBMITTED'}, '*');
              });
            } else {
              console.log('Form not found');
            }
          }
          
          // Run immediately and also set up a MutationObserver to handle dynamic content
          addListeners();
          const observer = new MutationObserver((mutations) => {
            for (let mutation of mutations) {
              if (mutation.type === 'childList') {
                addListeners();
              }
            }
          });
          observer.observe(document.body, { childList: true, subtree: true });
        `
      }, '*');
    };

    const handleError = () => {
      console.error('Error loading iframe');
      setError("Failed to load the form. Please try again later.");
      setIsLoading(false);
    };

    const handleMessage = (event) => {
      console.log('Received message:', event.data);
      if (event.origin === "https://airtable.com") {
        if (event.data && event.data.type === "AIRTABLE_FORM_SUBMITTED") {
          setIsSubmitted(true);
          console.log('Form submitted, pushing to dataLayer');
          try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'airtable_form_submitted',
              form_name: 'AirtableContactForm'
            });
            console.log('DataLayer push completed for form submission');
          } catch (error) {
            console.error('Error pushing form submission to dataLayer:', error);
          }
        } else if (event.data && event.data.type === "AIRTABLE_BUTTON_CLICKED") {
          console.log('Button clicked, pushing to dataLayer');
          try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'airtable_button_clicked',
              form_name: 'AirtableContactForm'
            });
            console.log('DataLayer push completed for button click');
          } catch (error) {
            console.error('Error pushing button click to dataLayer:', error);
          }
        }
      }
    };

    iframe.addEventListener('load', handleLoad);
    iframe.addEventListener('error', handleError);
    window.addEventListener('message', handleMessage);

    return () => {
      console.log('Cleaning up event listeners');
      iframe.removeEventListener('load', handleLoad);
      iframe.removeEventListener('error', handleError);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <motion.div
      className="airtable-embed-wrapper relative"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {isLoading && (
        <motion.div
          className="loading-overlay absolute inset-0 flex items-center justify-center bg-white bg-opacity-90 z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <p className="text-emerald-600 font-semibold">Loading form...</p>
        </motion.div>
      )}
      {error && (
        <motion.div
          className="error-message absolute inset-0 flex items-center justify-center bg-white bg-opacity-90 z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <p className="text-red-500 font-semibold">{error}</p>
        </motion.div>
      )}
      <iframe
        ref={iframeRef}
        className={`airtable-embed rounded-lg shadow-lg ${isSubmitted ? 'submitted' : ''}`}
        src="https://airtable.com/embed/appgvhIXoJUciFVbG/pagcc1ZJKlIZU8GK4/form"
        frameBorder="0"
        width="100%"
        height={isSubmitted ? "400" : "533"}
        style={{
          background: 'transparent',
          border: '1px solid #ccc',
          transition: 'all 0.3s ease-in-out',
        }}
        title="Airtable Contact Form"
      />
      <style jsx>{`
        .airtable-embed-wrapper {
          overflow: hidden;
          max-width: 100%;
        }
        .airtable-embed.submitted {
          transform: scale(0.9);
          overflow: hidden;
        }
      `}</style>
    </motion.div>
  );
};

export default AirtableContactForm;